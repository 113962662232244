import React from "react"
import styled from "styled-components"
const MainPageAdsContainer = styled.div`

.GoogleRow{
    display: flex;
    justify-content: center;
    align-items: center;
      max-width: 100%;
      min-width: 400px;
      min-height: 50px;

      .GoogleAdsLeaderboard {
        height: 90px;
        width: 728px;
        @media (max-width: 728px){
          height: 100px;
          width: 320px;
        }
      }
  }

`



class MainPageAds extends React.Component {
    
    render() {

  
    return(
        <>
        < MainPageAdsContainer className="GoogleRow">
            <ins className="adsbygoogle GoogleAdsLeaderboard"
                style={{display:'block', width:'100%', maxHeight:'90px', marginBottom:'16px'}}
                data-ad-client="ca-pub-1001229702754753"
                data-ad-slot="3464367011"></ins>

        </ MainPageAdsContainer>
        </>

    )
}
}
export default MainPageAds