import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import MainPageAds from "../components/blog/MainPageAds"
import Recaptcha from "react-google-recaptcha";

const BodyWrapper = styled.div`
  margin: 60px auto;
  max-width: 1000px;

  h1{
    font-size: 3.5rem;
    font-weight: 700;
  }

  p{font-size: 0.8rem;}
  
  .ContentHighlights{
    max-width: 1200px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-flow:column;
    h1, h2{
      color: #333333;
    }
    .carousel-indicators {
      display: none;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #333333;
      background-size: 80% 80%;
      border-radius: 50%;
    }
    
  }

  label{
    margin-top: 16px;
    font-weight:700;
    margin-bottom: 0;
  }

  .FocusedLabel{
    color:#4785C2;
  }

`;

const GoogleAds = styled.div`
.GoogleRow{
  display: flex;
  justify-content: center;
  align-items: center;
    max-width: 100%;
    min-width: 300px;
    min-height: 50px;
    .GoogleAdsLeaderboard {
      height: 90px;
      width: 728px;
      @media (max-width: 728px){
        height: 100px;
        width: 320px;
      }
    }
}`;

const RECAPTCHA_KEY = "6LcSRC8aAAAAAGqwE4sj5-TNm5CqScknvKB8Cmhq";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ShareYourStoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value });
  };

  handleSubmit = e => {
    e.preventDefault();
      var captcha = document.querySelector('#g-recaptcha-response').value;

    const form = e.target;
    if(document.getElementById('mail').value !== document.getElementById('confmail').value){
      document.querySelector('.alertblue').style.display = 'block';
    }
          else if (captcha === undefined ||
        captcha === '' ||
        captcha === null) {
        document.querySelector('.alertred').style.display = 'block';
      }

    else {
    
      function clearform(){
            /*Clear form*/
      var clearform= document.getElementById('ShareAStory');
      // var grecaptcha= document.getElementsByClassName('grecaptcha');
      clearform.reset();
      }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
    .then(res => {
      if (res) {
      document.querySelector('.alertred').style.display = 'none';
      document.querySelector('.alertblue').style.display = 'none';
      document.querySelector('.alertgreen').style.display = 'block';
      clearform();
      }
    })
      .catch(error => alert(error));

      setTimeout(function(){
        document.querySelector('.alertgreen').style.display = 'none';
        document.querySelector('.alertred').style.display = 'none';
        document.querySelector('.alertblue').style.display = 'none';
      },3000);
    }
  };

  render() {
    const siteTitle = "Anxious Extrovert";
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Share a story" />
        <BodyWrapper>
          <h1>Share a story</h1>
          <GoogleAds>
          <div className="row GoogleRow">
            <ins className="adsbygoogle GoogleAdsLeaderboard"
                style={{display:'block', width:'100%', maxHeight:'90px', marginBottom:'16px'}}
                data-ad-client="ca-pub-1001229702754753"
                data-ad-slot="7061256274"
></ins>

          </div>
          </GoogleAds>
          <p>Let someone know they aren't alone by sharing your mental health story or that of someone close to you. The Anxious Extrovert team will let you know if your story has been published on our blog via email.</p>
        <form autoComplete="on" id="ShareAStory" name="Mental Health Story Blogs" method="post" data-netlify="true"
          data-netlify-recaptcha="true"
          onSubmit={this.handleSubmit}
        >
          <label htmlFor="name">Name</label>
              <p>This is the name we'll us to call out the blog author. Feel free to use something quirky if you'd like to stay anonymous.</p>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <input placeholder="Danny" className="form-control" type="text" id="name" name="Name" required onChange={this.handleChange} />
                  </div>
              </div>
              <label htmlFor="mail">Email
              </label>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <input placeholder="12abc3@mail.com"className="form-control" type="email" id="mail" name="Email" autoComplete="on" required onChange={this.handleChange} />
                  </div>
              </div>
              <label htmlFor="confmail">Confirm Email
              </label>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <input placeholder="12abc3@mail.com"className="form-control" type="email" id="confmail" name="contact_email" autoComplete="on" required/>
                  </div>
              </div>

              <label htmlFor="Title" className="FocusedLabel">Title
              </label>
              <p>Give your story a title that sumarises it.</p>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <input placeholder="The time a felt low.." className="form-control" type="text" id="Title" name="Title" autoComplete="on" required maxLength="40" onChange={this.handleChange} />
                  </div>
              </div>

              <label htmlFor="Summary" className="FocusedLabel">Summary
              </label>
              <p>Summarise the story.</p>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <input placeholder="The time a felt low.." className="form-control" type="text" id="Summary" name="Summary" autoComplete="on" required maxLength="100" onChange={this.handleChange} />
                  </div>
              </div>

              <label htmlFor="Story" className="FocusedLabel">Your Story</label>
              <p>Share your story mental health story below. Be honest and be open, because your story might help someone else.</p>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <textarea maxLength="5000" rows="8" className="form-control" id="Story" name="Story" required onChange={this.handleChange} ></textarea>
                  </div>
              </div>
              <p className="newslettercheck">Would you like to subscribe to our newsletter?</p>
              <div className="">
                <label className="light radio-label-control" htmlFor="newsletter-yes">Yes please!!</label>
                <input type="radio" name="newsletter_consent" id="newsletter-yes" value="Has consented to joingin mailing list" onChange={this.handleChange} />
                <label className="light radio-label-control" htmlFor="newsletter-no">No thanks!!</label>
                <input type="radio" name="newsletter_consent" id="newsletter-no" value="Has not consented to joingin mailing list" defaultChecked onChange={this.handleChange} />
              </div>
              <p>We use MailerLite as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to MailerLite for processing. <a href="https://www.mailerlite.com/legal/privacy-policy" target="_blank" rel="noreferrer noopener">Learn more about MailerLite's privacy practices here.</a></p>
              <p>You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy practices, please see our <a href="/privacy-policy" target="_blank">privacy policy</a>.</p>
          <Recaptcha
            ref="recaptcha"
            sitekey={RECAPTCHA_KEY}
            onChange={this.handleRecaptcha}
          />
          <div className="alertgreen col-centered">
            Thank you for contacting us!!
          </div>
          <div className="alertblue col-centered">
              Please make sure your Emails match!!
            </div>  
          <div className="alertred col-centered">
            Please Confirm you are not a robot!!
          </div>  
          <button type="submit" className="formbutton col-md-3 margin-vert-20 col-centered">Send</button>

        </form>
        <MainPageAds/>
        </BodyWrapper>      
      </Layout>
    );
  }
}